'use client';

import React from 'react';
import { DialogClose } from '../dialog-close/DialogClose';
import { DialogBack } from '../dialog-back/DialogBack';

export type DialogHeaderProps = React.PropsWithChildren<{
  onClose: (event: any) => void;
  className?: string;
  title?: string;
  hideCloseButton?: boolean;
  onBackNav?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  showBackButton?: boolean;
}>;

export const DialogHeader = ({
  onClose,
  className,
  title,
  hideCloseButton,
  onBackNav,
  showBackButton,
  children,
}: DialogHeaderProps) => (
  <header className={className} slot="header">
    <DialogClose onClose={onClose} hideCloseButton={hideCloseButton} />
    {/* Always render this div even if empty to center the title */}
    <DialogBack onBackNav={onBackNav} showBackButton={showBackButton} />
    <div>
      {children ? children : null}
      {!children && title ? (
        <h2 className="font-medium fade-in">{title}</h2>
      ) : null}
    </div>
  </header>
);
