'use client';

import React from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { DialogHeader } from '../slots/dialog-header/DialogHeader';
import { DialogClose } from '../slots/dialog-close/DialogClose';
import {
  DialogRaw,
  type DialogRawProps,
} from '../components/dialog-raw/DialogRaw';

export type DialogProps = React.PropsWithChildren<
  Omit<DialogRawProps, 'render'> & {
    hideCloseButton?: boolean;
    title?: string;
    onBackNav?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
    showBackButton?: boolean;
  }
>;

// TODO: When React 19 is out, we can use the new ref as a prop instead of forwardRef
// https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop
export const Dialog = React.forwardRef<HTMLDialogElement, DialogProps>(
  (
    {
      onClose: onCloseCallback,
      open,
      className,
      hideCloseButton = false,
      size,
      title,
      children,
      onBackNav,
      showBackButton,
      ...rest
    },
    ref,
  ) => {
    return (
      <DialogRaw
        ref={ref}
        className={cssMerge(className)}
        onClose={onCloseCallback}
        open={open}
        size={size}
        {...rest}
        render={({ closeDialog }) => (
          <>
            {size === 'small' ? (
              <DialogClose
                onClose={closeDialog}
                hideCloseButton={hideCloseButton}
              />
            ) : (
              <DialogHeader
                onClose={closeDialog}
                hideCloseButton={hideCloseButton}
                title={title}
                onBackNav={onBackNav}
                showBackButton={showBackButton}
              />
            )}

            {children}
          </>
        )}
      />
    );
  },
);

Dialog.displayName = 'Dialog';
