import { Markdown } from '@vcc-package/text';
import { Track, TrackingProvider } from '@volvo-cars/tracking';
import React from 'react';

// Used to check if string contain html tags
// 'br', 'sup' and 'sub' are allowed html tags even in markdown
export const richTextRegex = /<\/?(?!br|sup|sub)\w*\b[^>]*>/m;

export const MarkdownOrRichText: React.FC<{
  text: string;
  enableTracking?: boolean;
  eventCategory?: string;
  eventLabel?: (linkText: string, linkUrl: string) => string;
  enableLists?: boolean;
  className?: string;
}> = ({
  text,
  enableTracking,
  eventCategory,
  eventLabel,
  enableLists,
  className,
  ...props
}) => {
  const isRichText = richTextRegex.test(text);

  if (!isRichText) {
    const markdownContent = (
      <Markdown
        className={className}
        enableLists={enableLists}
        markdown={text}
        enableTracking={enableTracking}
        trackEventAction="link|click"
        trackEventLabel={eventLabel}
        {...props}
      />
    );

    return enableTracking && eventCategory ? (
      <TrackingProvider eventCategory={eventCategory}>
        {markdownContent}
      </TrackingProvider>
    ) : (
      markdownContent
    );
  }

  const htmlContent = (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: text }}
      {...props}
    />
  );

  return enableTracking ? (
    <Track eventAction="link|click" eventLabel={`link text|${eventLabel}`}>
      {htmlContent}
    </Track>
  ) : (
    htmlContent
  );
};
