import React, { forwardRef } from 'react';
import { cssJoin } from '@volvo-cars/css/utils';

interface SpacingProps {
  children: React.ReactNode;
  topSpacing?: boolean;
}

export const Spacing = forwardRef<HTMLElement, SpacingProps>(
  ({ topSpacing, ...props }, ref?) => (
    <section
      ref={ref}
      className={cssJoin('mb-l', topSpacing && 'mt-l')}
      {...props}
    />
  ),
);

Spacing.displayName = 'Spacing';
