'use client';
import React from 'react';
import SharedBreadcrumbs from '@vcc-www/breadcrumbs';
import { TrackingProvider } from '@volvo-cars/tracking';
import { type BreadcrumbsProps as SharedBreadcrumbsProps } from '@vcc-www/breadcrumbs/Breadcrumbs';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { usePathname } from 'next/navigation';
import { type DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';
import { getSiteSlug } from '@volvo-cars/market-sites/src/getSiteSlug';
import { type SiteSlug } from '@volvo-cars/market-sites';

export const PageBreadcrumbs: React.FC<{
  currentTitle?: string;
}> = ({ currentTitle }) => {
  const pathname = usePathname();
  const market = getSiteSlug(pathname) as SiteSlug;
  const translate = useSharedComponentsTranslate();

  const trail: SharedBreadcrumbsProps['trail'] = ['home'];

  const splitPath = pathname?.split('?')[0].split('/').filter(Boolean) ?? [];
  const lastPath = splitPath[splitPath.length - 1];
  const pathsToTrail = splitPath.slice(1, -1);

  // Check if the last path is 'faq' and rename it to 'car-finance-faq'
  const updatedPathsToTrail = pathsToTrail.map((path) =>
    path === 'faq' ? 'car-finance-faq' : path,
  ) as SharedBreadcrumbsProps['trail'];

  const mergedTrail = [...trail, ...updatedPathsToTrail];

  const translatedTitle = translate(
    `Breadcrumbs.carFinance.${lastPath}` as keyof DictionaryItemTypes,
  );

  return (
    <TrackingProvider eventCategory="page breadcrumbs">
      <div data-autoid="breadcrumbs" className="container">
        <SharedBreadcrumbs
          trail={mergedTrail}
          currentTitle={currentTitle ?? translatedTitle}
          siteSlug={market}
        />
      </div>
    </TrackingProvider>
  );
};
