'use client';
import { getDictionariesProvider } from '@vcc-www/react-translate';
import { type DictionaryItemTypes } from 'src/content-management/DictionaryItemTypes';

const {
  useTranslate: useCbvTranslate,
  DictionariesProvider: CbvDictionariesProvider,
} = getDictionariesProvider<DictionaryItemTypes>();

export { useCbvTranslate, CbvDictionariesProvider };
