'use client';

import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';

export type DialogBackProps = {
  onBackNav?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  showBackButton?: boolean;
};

export const DialogBack = ({ onBackNav, showBackButton }: DialogBackProps) => {
  const translate = useSharedComponentsTranslate();

  return (
    <div slot="back">
      {showBackButton ? (
        <IconButton
          icon="chevron-back"
          className="fade-in"
          aria-label={translate('ReactOverlay.labels.back')}
          onClick={onBackNav}
          variant="clear"
          bleed
        />
      ) : null}
    </div>
  );
};
