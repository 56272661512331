'use client';

import React, { HTMLAttributes } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import {
  CarModel,
  GenericValue,
  LeaseType,
  Powertrain,
} from '@volvo-cars/tracking';
import { TrackLinks } from './TrackLinks';

export interface GA3Strict {
  selectedDealerName?: GenericValue;
  currencyCode?: GenericValue;
  carDrivelineType?: Powertrain | GenericValue;
  carModelName?: CarModel | GenericValue;
  market?: GenericValue;
  carEngine?: GenericValue;
  pageName?: GenericValue;
  pageType?: GenericValue;
  subPageName?: GenericValue;
  countryName?: GenericValue;
  marketLanguage?: GenericValue;
  signedIn?: 0 | 1;
  eventCategory?: GenericValue;
  eventAction?: GenericValue;
  eventLabel?: GenericValue;
  durationMileage?: GenericValue;
  leaseType?: LeaseType;
}

export type RichTextProps = HTMLAttributes<HTMLDivElement> & {
  extend?: ExtendCSS;
  html?: string;
  enableTracking?: boolean;
  trackEventLabel?: ((linkText: string, linkUrl: string) => string) | string;
  trackEventAction?: string;
  trackEventCategory?: ((linkText: string, linkUrl: string) => string) | string;
  trackGA3?: { [name: string]: GenericValue } | GA3Strict;
};

const RichText: React.FC<React.PropsWithChildren<RichTextProps>> = ({
  extend,
  html,
  enableTracking,
  trackEventLabel,
  trackEventAction,
  trackEventCategory,
  trackGA3,
  ...props
}) => {
  const content = html ? (
    <Block
      extend={extend}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  ) : null;

  return enableTracking && html ? (
    <TrackLinks
      trackEventLabel={trackEventLabel}
      trackEventAction={trackEventAction}
      trackEventCategory={trackEventCategory}
      trackGA3={trackGA3}
    >
      {content}
    </TrackLinks>
  ) : (
    content
  );
};

export { TrackLinks };

export default RichText;
