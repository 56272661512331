'use client';

import React, { useRef, useEffect } from 'react';
import { useTracker } from '@volvo-cars/tracking';

import { type RichTextProps } from './index';

export const TrackLinks: React.FC<
  React.PropsWithChildren<
    Pick<
      RichTextProps,
      'trackEventLabel' | 'trackEventAction' | 'trackEventCategory' | 'trackGA3'
    >
  >
> = ({
  trackEventLabel,
  trackEventAction,
  trackEventCategory,
  trackGA3,
  children,
}) => {
  const divRef = useRef<HTMLElement>(null);
  const track = useTracker(null, { ga3: trackGA3 });

  useEffect(() => {
    divRef.current?.addEventListener('click', (e) => {
      const target = e.target as HTMLAnchorElement;
      if (target.tagName === 'A') {
        const linkText = target.text.toLowerCase().replace(/ /g, '_');

        const eventLabel =
          typeof trackEventLabel === 'function'
            ? trackEventLabel(target.text, target.href)
            : trackEventLabel;

        const eventCategory =
          typeof trackEventCategory === 'function'
            ? trackEventCategory(target.text, target.href)
            : trackEventCategory;

        track.interaction({
          eventLabel: eventLabel ?? `${target.pathname}|${linkText}`,
          eventAction: trackEventAction ?? 'link',
          ...(eventCategory && { eventCategory }),
        });
      }
    });
  }, [divRef, track, trackEventAction, trackEventLabel, trackEventCategory]);

  const child = React.Children.only(children);

  return React.cloneElement(child as React.ReactElement, { ref: divRef });
};
