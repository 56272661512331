import React from 'react';
import { cssMerge } from '@volvo-cars/css/utils';

export const DialogMain: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = (props) => {
  return (
    <article
      slot="main"
      className={cssMerge(props.className)} // cssMerge is used to ensure className is cleaned up
    >
      {props.children}
    </article>
  );
};
