import {
  AccordionSummary,
  AccordionDetails,
} from '@volvo-cars/react-accordion';
import { useTracker } from '@volvo-cars/tracking';

import { MarkdownOrRichText } from './MarkdownOrRichText';
import styles from './faq.module.css';

export interface Question {
  question: string;
  answer: string;
  enableMarkdownLists?: boolean;
  testId?: string;
  topic?: string;
  onTrackClick?: () => void;
  eventCategory?: string;
}

export const FaqQuestion: React.FC<React.PropsWithChildren<Question>> = ({
  question,
  answer,
  enableMarkdownLists,
  testId,
  onTrackClick,
  eventCategory,
}) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });

  return (
    <AccordionDetails
      data-testid={testId}
      onToggle={(open) => {
        if (onTrackClick) {
          onTrackClick();
        } else {
          ga4Tracker.customEvent({
            eventCategory: eventCategory || 'faq question',
            eventAction: open ? 'accordion|expand' : 'accordion|collapse',
            eventLabel: `question|${question.toLowerCase()}`,
          });
        }
      }}
    >
      <AccordionSummary>{question}</AccordionSummary>
      <div className="mb-16">
        <QuestionAnswer
          answer={answer}
          testId={testId}
          eventCategory={eventCategory}
          enableMarkdownLists={enableMarkdownLists}
        />
      </div>
    </AccordionDetails>
  );
};

interface QuestionAnswerProps {
  answer: string;
  testId?: string;
  eventCategory?: string;
  enableMarkdownLists?: boolean;
}

const QuestionAnswer: React.FC<
  React.PropsWithChildren<QuestionAnswerProps>
> = ({ answer, testId, eventCategory, enableMarkdownLists = true }) => (
  <div className={styles['faq-question-body']}>
    <MarkdownOrRichText
      data-testid={`${testId}__answer`}
      text={answer}
      eventCategory={eventCategory || 'faq question'}
      enableLists={enableMarkdownLists}
      enableTracking
    />
  </div>
);
