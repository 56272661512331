'use client';

import React, { FC } from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { SiteSlug } from '@volvo-cars/market-sites';
import {
  Breadcrumbs,
  BreadcrumbsStructuredData,
} from '@volvo-cars/react-breadcrumbs';
import { LinkBaseProps } from '@volvo-cars/react-breadcrumbs/dist/Link';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';

// Temporary fix for standalone mode not recognizing i18n.js imports
if (typeof window === 'undefined') {
  //@ts-ignore
  import('@volvo-cars/react-breadcrumbs/i18n/i18n.js').then(() => {});
}

const trailMap = (
  origin: string,
  siteSlug: SiteSlug,
  translate: ReturnType<typeof useSharedComponentsTranslate>,
) => ({
  home: {
    title: (translate('Breadcrumbs.breadcrumbs.home') || '') as string,
    href: `${origin}/${siteSlug}/`,
  },
  suv: {
    title: (translate('Breadcrumbs.breadcrumbs.suv') || '') as string,
    href: `${origin}/${siteSlug}/cars/suv/`,
  },
  sedan: {
    title: (translate('Breadcrumbs.breadcrumbs.sedan') || '') as string,
    href: `${origin}/${siteSlug}/cars/sedan/`,
  },
  wagon: {
    title: (translate('Breadcrumbs.breadcrumbs.wagon') || '') as string,
    href: `${origin}/${siteSlug}/cars/wagon/`,
  },
  'legacy-models': {
    title: (translate('Breadcrumbs.breadcrumbs.legacy-models') || '') as string,
    href: `${origin}/${siteSlug}/cars/legacy-models/`,
  },
  'car-finance': {
    title: (translate('Breadcrumbs.carFinance.car-finance') || '') as string,
    href: `${origin}/${siteSlug}/car-finance/`,
  },
  'car-finance-faq': {
    title: (translate('Breadcrumbs.carFinance.faq') || '') as string,
    href: `${origin}/${siteSlug}/car-finance/faq/`,
  },
  'care-by-volvo': {
    title: (translate('Breadcrumbs.carFinance.care-by-volvo') || '') as string,
    href: `${origin}/${siteSlug}/care-by-volvo/`,
  },
});

export interface BreadcrumbsProps {
  trail: (keyof ReturnType<typeof trailMap> | LinkBaseProps)[];
  currentTitle: string;
  siteSlug: SiteSlug;
}

export const SharedBreadcrumbs: FC<BreadcrumbsProps> = ({
  trail,
  currentTitle,
  siteSlug,
}) => {
  const sharedTranslate = useSharedComponentsTranslate();
  const url = useVolvoCarsUrl();
  const trails = trailMap(url.origin, siteSlug, sharedTranslate);

  const generatedTrail: LinkBaseProps[] = trail.map((item) =>
    typeof item === 'object'
      ? { ...item, trackEventLabel: `${item.title} | ${item.href}` }
      : {
          ...trails[item],
          trackEventLabel: `${trails[item].title} | ${trails[item].href}`,
        },
  );

  return (
    <>
      <BreadcrumbsStructuredData
        trail={generatedTrail}
        currentTitle={currentTitle}
      />
      <Breadcrumbs trail={generatedTrail} currentTitle={currentTitle} />
    </>
  );
};

export default SharedBreadcrumbs;
